<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";

    import Repository from "../../../app/repository/repository-factory";

    const SubaccountRepository = Repository.get("SubaccountRepository");

    export default {
        components: {Layout, PageHeader},
        data() {
            return {
                isBusy: false,
                excelInProgress: false,
                title: "Lista podopiecznych",
                totalRows: 1,
                items: [],
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: "",
                filterOn: [''],
                sortBy: 'createdAt',
                sortDesc: true,
                fields: []
            };
        },
        watch: {
            filter: function () {
                this.currentPage = 1;
            }
        },
        methods: {
            getExcel: function () {
                this.excelInProgress = true;
                const promise = SubaccountRepository.getExcel();
                return promise.then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Podopieczni.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    this.excelInProgress = false;
                }).catch(error => {
                    console.log(error);
                    this.excelInProgress = false;
                })
            },
            axiosProvider(ctx) {

                const parameters = '?page=' + ctx.currentPage + '&maxItems=' + ctx.perPage + '&search=' + this.filter + '&orderBy=' + this.sortBy + '&orderDesc=' + this.sortDesc;
                const promise = SubaccountRepository.getGrid(parameters);

                return promise.then((data) => {
                    const fields = data.data.info.fields;

                    this.totalRows = data.data.info.countAllItems;

                    let fieldsArray = [];
                    fieldsArray.push({'key': 'avatar', 'label': "#"});
                    Object.entries(fields).forEach(([key, val]) => {
                        if (val.disabled !== true) {
                            val.key = key;
                            fieldsArray.push(val);
                        }
                    });

                    fieldsArray.push({'key': 'saldo', 'label': "Saldo", 'tdClass' : 'text-right'});
                    fieldsArray.push({'key': 'isActive', 'label': "Aktywny?"});
                    fieldsArray.push({'key': 'action', 'label': "Akcje"});
                    this.fields = fieldsArray;
                    return data.data.data || []
                }).catch(error => {
                    console.log(error);
                    return []
                })
            },
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-7 col-lg-6">
                                    <b-form inline>
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                        <div class="ml-2 search-box">
                                            <div class="position-relative">
                                                <b-form-input v-model="filter" :debounce="350" placeholder="Szukaj..."></b-form-input>
                                                <i class="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                    </b-form>
                                </div>
                                <div class="col-sm-5 col-lg-6">
                                    <div class="text-sm-right">
                                        <b-link :to="{ name: 'Dodaj podopiecznego' }">
                                            <button type="button"
                                                    class="btn btn-primary waves-effect waves-light mb-2 mr-2">
                                                <i class="mdi mdi-plus mr-1"></i> Dodaj podopiecznego
                                            </button>
                                        </b-link>
                                        <button type="button" v-on:click="getExcel"
                                                class="btn btn-success waves-effect waves-light mb-2 mr-2">
                                            <i class="fas fa-file-excel mr-1"></i> Export do Excela
                                            <template v-if="excelInProgress">
                                                <b-spinner small class="m-0" variant="light" role="status"
                                                           type="grow"></b-spinner>
                                            </template>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- End search -->
                            <!-- Table -->
                            <div class="table-responsive mb-0">
                                <b-table
                                        :items="axiosProvider"
                                        :fields="fields"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn">

                                    <template v-slot:table-busy>
                                        <div class="text-center text-black">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong> Ładowanie danych...</strong>
                                        </div>
                                    </template>

                                    <template v-slot:cell(avatar)="row">
                                        <div v-if="row.item.profileImageUrl == ''" class="avatar-xs">
                                            <span class="avatar-title rounded-circle">{{ row.item.firstName.substring(0,1).toLocaleUpperCase() }}</span>
                                        </div>
                                        <div v-if="row.item.profileImageUrl !== ''" class="avatar-xs">

                                                <img :src="row.item.profileImageUrl" class="rounded-circle avatar-xs"/>

                                        </div>
                                    </template>

                                    <template v-slot:cell(email)="row">
                                       <a :href="'mailto:' + row.item.email">{{ row.item.email }}</a>
                                    </template>

                                    <template v-slot:cell(saldo)="row">
                                        <strong>{{ (row.item.personalAccountBalance + row.item.publicAccountBalance + row.item.onePercentAccountBalance).toFixed(2) }} zł</strong>
                                    </template>

                                    <template v-slot:cell(isActive)="data">
                                        <span class="badge badge-pill badge-soft-success font-size-12"
                                              :class=" { 'badge-soft-danger': data.value == false,
                                                'badge-soft-success': data.value == true }">
                                                {{ data.value ? 'Tak' : 'Nie' }}</span>
                                    </template>

                                    <template v-slot:cell(action)="row">
                                        <ul class="list-inline font-size-20 contact-links mb-0">
                                            <li class="list-inline-item px-2">
                                                <a v-b-tooltip.hover title="Przejście do profilu">
                                                    <b-link :to="{ name: 'Profil podopiecznego', params: { id: row.item.uuid } }">
                                                        <i class="bx bx-user-circle"></i></b-link>
                                                </a>
                                            </li>
                                            <li class="list-inline-item px-2">
                                                <a v-b-tooltip.hover title="Edycja danych">
                                                    <b-link :to="{ name: 'Edycja podopiecznego', params: { id: row.item.uuid } }">
                                                        <i class="bx bx-edit"></i></b-link>
                                                </a>
                                            </li>
                                        </ul>
                                    </template>

                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <template v-if="totalRows === 0">
                                        <div class="d-flex justify-content-center mt-3">
                                            <b>Nie znaleziono elementów</b>
                                        </div>
                                    </template>
                                    <template v-if="totalRows > 0">
                                        <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                                            Znaleziono {{ totalRows }} rekordów.
                                        </div>
                                    </template>
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="totalRows"
                                                          :per-page="perPage"
                                                          first-number last-number></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Layout>
</template>
